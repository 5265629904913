export async function submitGravityForm(
  formId: number | null | undefined,
  form: {
    [key: string]: string;
  }
) {
  const data = {
    formId: formId,
    formData: {
      input_values: Object.assign({}, form),
      api_key: '2fa97dc189',
    },
  };
  const headers = { 'Content-Type': 'application/json' };
  const res = await fetch(`/api/submitGravityForm`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });

  const json = await res.json();
  if (json.errors || json.error) {
    return { error: 'Failed to submit form' };
  }
  return json;
}
