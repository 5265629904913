import styles from '../styles/components/gravity-form-field.module.scss';
import type { GravityFormField as FieldType } from '../types/api';

interface Props {
  field: FieldType;
  onUpdateFieldValue: (e: any) => void;
}

export default function GravityFormField({ field, onUpdateFieldValue }: Props): JSX.Element | null {
  const element = () => {
    switch (field.type.toLowerCase()) {
      case 'select':
        return <Select field={field} onUpdateFieldValue={onUpdateFieldValue} />;
      case 'textarea':
        return <TextArea field={field} onUpdateFieldValue={onUpdateFieldValue} />;
      case 'checkbox':
        return <Input field={field} onUpdateFieldValue={onUpdateFieldValue} />;
      case 'fileupload':
        return <FileUpload field={field} onUpdateFieldValue={onUpdateFieldValue} />;
      default:
        return <Default field={field} onUpdateFieldValue={onUpdateFieldValue} />;
    }
  };

  const gridWidth = () => {
    switch (field.layoutGridColumnSpan) {
      case 6:
        return styles.col_6;
      case 12:
        return styles.col_12;
      default:
        return styles.col_12;
    }
  };

  return <div className={`${styles.col} ${gridWidth()}`}>{element()}</div>;
}

function Select({ field, onUpdateFieldValue }: Props) {
  return (
    <div className={styles.gravity_form_field__wrapper}>
      <label htmlFor="name">{field.label}</label>
      <select placeholder={'Please Select...'} name={`input_${field.id}`} onChange={onUpdateFieldValue} required>
        {field?.choices?.map(function (choice, i) {
          return (
            <option key={i} value={choice.value}>
              {choice.value}
            </option>
          );
        })}
      </select>
    </div>
  );
}

function TextArea({ field, onUpdateFieldValue }: Props) {
  return (
    <div className={styles.gravity_form_field__wrapper}>
      <label htmlFor="name">{field.label}</label>
      <textarea name={`input_${field.id}`} onChange={onUpdateFieldValue} required />
    </div>
  );
}

function Input({ field, onUpdateFieldValue }: Props) {
  return (
    <div className={styles.gravity_form_field__wrapper}>
      <div className={styles.gravity_form_checkbox__outer_wrapper}>
        {field?.choices?.map((choice, index) => {
          return (
            <div key={index} className={styles.gravity_form_checkbox__inner_wrapper}>
              <input id={`input_${field.id}_${index + 1}`} name={`input_${field.id}_${index + 1}`} type={'checkbox'} value={choice.value} onChange={onUpdateFieldValue} />
              <label htmlFor={`input_${field.id}_${index + 1}`} className={styles.gravity_form_checkbox__label}>
                {choice.text}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function FileUpload({ field, onUpdateFieldValue }: Props) {
  return (
    <div className={styles.gravity_form_field__wrapper}>
      <label htmlFor={`input_${field.id}`}>{field.label}</label>
      <p className={styles.file_upload_note}>Upload PDF file (PDF format only)</p>
      <input type="file" id={`input_${field.id}`} name={`input_${field.id}`} onChange={onUpdateFieldValue} required />
    </div>
  );
}

function Default({ field, onUpdateFieldValue }: Props) {
  return (
    <div className={styles.gravity_form_field__wrapper}>
      <label htmlFor="name">{field.label}</label>
      <input type={field.type} name={`input_${field.id}`} onChange={onUpdateFieldValue} required />
    </div>
  );
}
