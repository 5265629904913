import classNames from 'classnames';
import styles from '../styles/components/gravity-form.module.scss';
import type { GravityForm as FormType, GravityFormField as FieldType } from '../types/api';
import GravityFormField from './gravity-form-field';
import { useState } from 'react';
import { submitGravityForm } from '../lib/submitGravityForm';

interface Props {
  gravityForm?: FormType;
  hideTitle?: boolean;
}

export default function GravityForm({ gravityForm, hideTitle }: Props): JSX.Element {
  const form = gravityForm;
  const formClass = classNames(form?.cssClass);
  const [formToSubmit, setFormToSubmit] = useState<{ [key: string]: string }>({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showForm, setShowForm] = useState(true);

  function updateErrorMessage(error: any) {
    setErrorMessage(error);
    setSuccessMessage('');
  }

  function updateSuccessMessage(successMessage: any) {
    setErrorMessage('');
    setSuccessMessage(successMessage);
  }

  async function submitForm(e: any) {
    e.preventDefault();

    const response = await submitGravityForm(form?.formId, formToSubmit);

    if (response && response.error) {
      updateErrorMessage('Error: ' + response.error);
      setShowForm(true);
    } else if (response && response == 200) {
      updateSuccessMessage(form?.confirmations[0].message);
      setFormToSubmit({});
      setShowForm(false);
    } else {
      //TODO: Raise Sentry Error
      updateErrorMessage("Error: We're having trouble receiving your submission. Please try again later");
    }
  }

  const getBase64 = (file: File): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = (err) => reject(err);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
          return;
        }
        resolve(null);
      };
      reader.readAsDataURL(file);
    });
  };

  function onUpdateFieldValue(e: any) {
    const updatedFormToSubmit: { [key: string]: string } = { ...formToSubmit };
    /*
     * Read Files
     * Loop through files if not null
     * Read each files as base64
     */
    if (e.target.files) {
      const files = e.target.files;
      const filePromises = Array.from(files as FileList).map((file: File) => getBase64(file));

      Promise.all(filePromises).then((base64Files) => {
        base64Files.forEach((base64File, i) => {
          if (base64File !== null) {
            updatedFormToSubmit[e.target.name] = base64File;
          }
        });
        setFormToSubmit(updatedFormToSubmit);
      });
    } else {
      updatedFormToSubmit[e.target.name] = e.target.value;
      setFormToSubmit(updatedFormToSubmit);
    }
  }

  return (
    <div className={styles.form_container_wrapper}>
      {!hideTitle && <h2 className={styles.form_title}>{form?.title}</h2>}
      {form?.description && <p className={styles.form_description}>{form?.description}</p>}

      <form id={formClass} className={`${styles.form_container}`} onSubmit={submitForm}>
        {/* Honeypot input - discard submission if filled */}
        <input type="text" name="tell_us_what_is_up" style={{ display: 'none' }} autoComplete="off" onChange={onUpdateFieldValue} />

        {showForm && (
          <>
            {form?.formFields?.nodes?.map((field: FieldType, i: number) => {
              return <GravityFormField key={i} field={field} onUpdateFieldValue={onUpdateFieldValue} />;
            })}

            <div className={styles.form_submit_button_wrapper}>
              <button className={`${styles.form_submit_button} btn btn-primary`}>{form?.button?.text}</button>
            </div>
          </>
        )}

        <div className={styles.form_error_message}>{errorMessage}</div>
        <div
          className={`${styles.form_success_message} ${formClass}`}
          dangerouslySetInnerHTML={{
            __html: successMessage,
          }}
        />
      </form>
    </div>
  );
}
