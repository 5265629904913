import { GRAVITY_FORMS_FIELDS } from '../graphql-fragments/gravityFormFields';
import { fetchAPI } from '../api';

export async function getPageForm(formId?: string) {
  const data = await fetchAPI(
    `
    query Form {
      form: gfForm(id: "${formId}", idType: DATABASE_ID) {
        id
        cssClass
        formId
        button {
          text
        }
        ${GRAVITY_FORMS_FIELDS}
        title
        description        
        confirmations {
          message
        }
      }
    }
  `,
    {
      variables: {},
    }
  );
  return data;
}
