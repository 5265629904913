import narrowStyles from '../../styles/components/blocks/request-a-callback-button/request-a-callback-button.narrow.module.scss';
import fullWidthStyles from '../../styles/components/blocks/request-a-callback-button/request-a-callback-button.full-width.module.scss';
import modalStyles from '../../styles/components/blocks/request-a-callback-button/request-a-callback-button.modal.module.scss';
import type { Attributes } from '../../types/blocks/attributes';
import Modal from 'react-modal';
import { useState } from 'react';
import phoneIcon from '../../public/images/icons/phone.svg';
import headphonesIcon from '../../public/images/icons/headphones.svg';
import closeIcon from '../../public/images/icons/close.svg';
import GravityForm from '../gravity-form';
import { getPageForm } from '../../lib/queries/getPageForm';
import { useEffect } from 'react';

interface Props {
  title?: string;
  content?: string;
  show_icon?: boolean;
  button_text?: string;
  modal_title?: string;
  modal_text?: string;
  modal_form_id?: string;
  attributes?: Attributes;
  add_top_margin?: boolean;
  display_as_full_width?: boolean;
  wysiwyg_text?: string;
}

export default function RequestACallbackButton({
  title,
  content,
  show_icon,
  button_text,
  modal_title,
  modal_text,
  attributes,
  modal_form_id,
  add_top_margin,
  display_as_full_width,
  wysiwyg_text,
}: Props): JSX.Element {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState();

  const openModal = () => {
    setIsOpen(true);
    document?.querySelector('html')?.classList.add('no-scroll');
  };
  const closeModal = () => {
    setIsOpen(false);
    document?.querySelector('html')?.classList.remove('no-scroll');
  };

  const getForm = async (formId?: string) => {
    if (!form) {
      const formResponse = await getPageForm(formId);
      setForm(formResponse.form);
    }
  };
  useEffect(() => {
    getForm(modal_form_id);
  });

  let styles = display_as_full_width ? fullWidthStyles : narrowStyles;

  return (
    <div id={attributes?.anchor} className="container">
      <div className={`${styles.request_a_callback__wrapper} ${styles.request_a_callback__two_col}`}>
        {show_icon && display_as_full_width ? (
          <div className={styles.request_a_callback__headphone}>
            <img src={headphonesIcon.src} alt="Phone Icon" />
          </div>
        ) : null}
        <div className={styles.request_a_callback__content_wrapper}>
          {show_icon && !display_as_full_width ? (
            <div className={`${styles.request_a_callback__headphone} ${add_top_margin ? styles.extra_margin : ""}`}>
              <img src={headphonesIcon.src} alt="Phone Icon" />
            </div>
          ) : null}
          {title && <h3 className={styles.request_a_callback__title}>{title}</h3>}
          {content && <div className={styles.request_a_callback__content}>{content}</div>}
          {display_as_full_width ? null : (
            <>
              {button_text && (
                <div onClick={openModal} className={`${styles.request_a_callback__button} btn btn-prmary`}>
                  {button_text}
                </div>
              )}
            </>
          )}
        </div>
        {display_as_full_width ? (
          <>
            {button_text && (
              <div onClick={openModal} className={`${styles.request_a_callback__button} btn btn-prmary`}>
                {button_text}
              </div>
            )}
          </>
        ) : null}

        {display_as_full_width ? null : <div className={styles.wysiwygText} dangerouslySetInnerHTML={{ __html: wysiwyg_text ? wysiwyg_text : '' }}></div>}
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Request a Callback" className={modalStyles.request_a_callback__modal}>
        <div className={modalStyles.request_a_callback__modal_phone}>
          <img src={phoneIcon.src} alt="Close Icon" />
        </div>
        <h2 className={modalStyles.request_a_callback__modal_title}>{modal_title}</h2>
        <div onClick={closeModal} className={modalStyles.request_a_callback__modal_close}>
          <img src={closeIcon.src} alt="Close Icon" />
        </div>
        {modal_text && <div className={modalStyles.request_a_callback__modal_content}>{modal_text}</div>}
        {form && <GravityForm gravityForm={form} hideTitle={true} />}
      </Modal>
    </div>
  );
}
