export const GRAVITY_FORMS_FIELDS = `
	formFields {
		nodes {
			layoutGridColumnSpan
			layoutSpacerGridColumnSpan
			... on AddressField {
				cssClass
				label
				id
			}
			... on CaptchaField {
				cssClass
				label
				id
			}
			... on CheckboxField {
				cssClass
				label
				id
				choices {
					text
					value
				}
			}
			... on ConsentField {
				cssClass
				label
				id
			}
			... on DateField {
				cssClass
				label
				id
			}
			... on EmailField {
				cssClass
				label
				id
			}
			... on FileUploadField {
				cssClass
				label
				id
			}
			... on HiddenField {
				label
				id
			}
			... on HtmlField {
				cssClass
				label
				id
			}
			... on ListField {
				cssClass
				label
				id
			}
			... on MultiSelectField {
				cssClass
				label
				id
			}
			... on NameField {
				cssClass
				label
				id
			}
			... on NumberField {
				cssClass
				label
				id
			}
			... on PageField {
				cssClass
				id
			}
			... on PasswordField {
				cssClass
				label
				id
			}
			... on PhoneField {
				cssClass
				label
				id
			}
			... on PostCategoryField {
				cssClass
				label
				id
			}
			... on PostContentField {
				cssClass
				label
				id
			}
			... on PostCustomField {
				cssClass
				label
				id
			}
			... on PostExcerptField {
				cssClass
				label
				id
			}
			... on PostImageField {
				cssClass
				label
				id
			}
			... on PostTagsField {
				cssClass
				label
				id
			}
			... on PostTitleField {
				cssClass
				label
				id
			}
			... on RadioField {
				cssClass
				label
				id
			}
			... on SectionField {
				cssClass
				label
				id
			}
			... on SelectField {
				cssClass
				label
				id
				choices {
					text
					value
				}
			}
			... on TextAreaField {
				cssClass
				label
				id
			}
			... on TextField {
				cssClass
				label
				id
			}
			... on TimeField {
				cssClass
				label
				id
			}
			... on WebsiteField {
				cssClass
				label
				id
			}
			type
		}
	}
`;
