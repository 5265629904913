import * as Sentry from '@sentry/nextjs';

const API_URL = `${process.env.NEXT_PUBLIC_WP_API}`;

export async function fetchAPI(
  query: string,
  { variables }: { variables?: unknown }
) {
  try {
    const headers = { 'Content-Type': 'application/json' };
    const res = await fetch(API_URL + "graphql", {
      method: 'POST',
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
      keepalive: false, // This is to attempt to fix socket hang up issue
                        // Reference: https://stackoverflow.com/questions/62818064/keep-getting-socket-hang-up-when-using-node-fetch/62818137#62818137
                        // My thoughts are:
                        //  - CMS is running on HTTP 1.1 which does not support multiplexing
                        //  - Next can trigger multiple requests at the same time during peak usage
                        //  - The server is not able to handle the requests fast enough, causing the socket to hang up
    });

    const json = await res.json();

    if (json.errors) {
      throw new Error(`Failed to fetch API: ${JSON.stringify(json.errors)}`);
    }
    return json.data;
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        query,
        variables,
      },
    });

    // Throw the error again so ISR serves old data, therefore
    // need to check whether it is server side
    if (typeof window === 'undefined') {
      throw e;
    }
  }

  return {};
}
